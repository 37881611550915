import 'zone.js/plugins/zone-error';

import { IEnvironment } from './base';

export const environment = {
  production: false,
  development: true,
  baseHref: '/',
  apiDomain: 'https://services.sandbox.development.panopticerp.com',
  apiUrl: '/gateway/api/v1/',
  reportServerUrl: 'http://reports.sandbox.reptube.demo.eleks.com/ReportServer/Pages/ReportViewer.aspx',
  apiVersion: 'v1',
  serviceWorkerPath: './ngsw-worker.js',
  msalConfig: {
    auth: {
      clientId: 'c644faa7-28e8-47c1-8db5-802d6467c610'
    }
  },
  apiConfig: {
    scopes: ['https://panoptictest.onmicrosoft.com/b4bd053d-5540-4c58-b86c-e83d226f3a73/user_impersonation'],
    uri: 'https://app.sandbox.development.panopticerp.com/'
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
      resetPassword: 'B2C_1A_PASSWORDRESET',
      editProfile: 'b2c_1_edit_profile'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://panoptictest.b2clogin.com/panoptictest.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN'
      },
      resetPassword: {
        authority: 'https://panoptictest.b2clogin.com/panoptictest.onmicrosoft.com/B2C_1A_PASSWORDRESET'
      },
      editProfile: {
        authority: 'https://panoptictest.b2clogin.com/panoptictest.onmicrosoft.com/b2c_1_edit_profile'
      }
    },
    authorityDomain: 'panoptictest.b2clogin.com'
  }
} as IEnvironment;
