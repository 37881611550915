import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts/http/base-http.service';
import { ICollectionResponse } from '../abstracts/http/collection-response.interface';
import {
  ISalesOrderLineByReference,
  ISalesOrderListResponse,
  ISalesOrderPreview,
  ISalesOrderReferences,
  ISalesOrderResponse,
  ISelectSalesOrderIncomplete,
  IMaterialBySoLine,
  ISalesOrder,
  IERPSalesOrderPatch,
  ISalesOrderReleaseLine,
  ISalesOrderReleaseListResponse,
  ISalesOrderReleaseResponse,
  ISalesOrderRelease,
  ILoadingAuthSalesOrder,
  IPurchaseRequisitionAttachments,
  IERPSalesOrderAttachments,
  ISalesOrderRevision,
  ISalesOrderAvaluableTransitions,
  ISalesOrderAssociatedWorkOrders
} from '../interfaces';

import { ISelectOption } from '../../../../components/src/lib';
import { ERPSharedSalesOrderFactory } from './shared-sales-order.factory';
import { Observable, of } from 'rxjs';
import { IERPSalesOrderProgresStatus, IUser } from '../interfaces';
import { ERPSalesOrderReleaseFactory } from './sales-order-release.factory';
import { IdValue } from '../types';
import { Query } from '../builders';
import { ERPSalesOrderAttachmentsCategory } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class ERPSharedSalesOrderService extends BaseHttpService {
  constructor(
    readonly httpClient: HttpClient,
    readonly salesOrderFactory: ERPSharedSalesOrderFactory,
    readonly salesOrderReleaseFactory: ERPSalesOrderReleaseFactory
  ) {
    super('salesOrder');
  }

  // original methods
  getSalesRepresentatives(params?: object): Observable<ICollectionResponse<IUser[]>> {
    return this.get<ICollectionResponse<IUser[]>>(params, 'salesRepresentatives');
  }

  getSalesOrders(params: object) {
    return this.get<ICollectionResponse<ISalesOrderListResponse>>(params);
  }

  getSalesOrdersIncomplete(params: object): Observable<ICollectionResponse<ISelectSalesOrderIncomplete>> {
    return this.get<ICollectionResponse<ISelectSalesOrderIncomplete>>(params, 'incomplete');
  }

  getSalesOrdersNames(searchString: string): Observable<string[]> {
    return this.get<string[]>({ searchString }, 'names');
  }

  getSalesOrdersPreview(id: number, params?: object): Observable<ICollectionResponse<ISalesOrderPreview>> {
    return this.get<ICollectionResponse<ISalesOrderPreview>>(params, `preview/${id}`);
  }

  getLines(params: object, soId: string) {
    return this.get<ICollectionResponse<ISalesOrderLineByReference>>(params, `${soId}/lines/reference`);
  }

  getMaterials(params: object, soId: string, lineId: string) {
    return this.get<ICollectionResponse<IMaterialBySoLine>>(params, `${soId}/lines/${lineId}/materials/reference`);
  }

  save(order: ISalesOrder) {
    const request = this.salesOrderFactory.toRequest(order);

    return this.post<ISalesOrderResponse>(request).pipe(map(order => this.salesOrderFactory.fromResponse(order)));
  }

  update(order: ISalesOrder): Observable<ISalesOrder> {
    const request = this.salesOrderFactory.toRequest(order);

    return this.put<ISalesOrderResponse>(request, {}, request.id).pipe(
      map(order => this.salesOrderFactory.fromResponse(order))
    );
  }

  patchSOStatus(
    status: IERPSalesOrderPatch<{ SalesOrderStatus: IdValue }>,
    id: string
  ): Observable<{ message: string } | void> {
    return this.patch(status, {}, id);
  }

  updateSalesOrder(order: ISalesOrder): Observable<ISalesOrderResponse> {
    const request = this.salesOrderFactory.toRequest(order);

    return this.put<ISalesOrderResponse>(request, {}, request.id);
  }

  getSalesOrdersReleasesPreview(soId: number) {
    return this.get<ISalesOrderReleaseLine[]>({}, `${soId}/releases/preview`);
  }

  getSalesOrdersReleases(soId: number, params: object) {
    return this.get<ICollectionResponse<ISalesOrderReleaseListResponse>>(params, `${soId}/releases`);
  }

  getAllSalesOrdersReleases(params: object) {
    return this.get<ICollectionResponse<ISalesOrderReleaseListResponse>>(params, `releases`);
  }

  getSalesOrdersRelease(soId: number, releaseId: number) {
    return this.get<ISalesOrderReleaseResponse>({}, `${soId}/releases/${releaseId}`).pipe(
      map(response => this.salesOrderReleaseFactory.fromResponse(response))
    );
  }

  createSalesOrdersRelease(soId: number, release: ISalesOrderRelease) {
    const request = this.salesOrderReleaseFactory.toRequest(release);

    return this.post<ISalesOrderReleaseResponse>(request, {}, `${soId}/releases`);
  }

  updateSalesOrdersRelease(soId: number, release: ISalesOrderRelease) {
    const request = this.salesOrderReleaseFactory.toRequest(release);

    return this.put<ISalesOrderReleaseResponse>(request, {}, `${soId}/releases/${release.id}`);
  }

  deleteSalesOrdersRelease(soId: number, releaseId: number) {
    return this.delete({}, `${soId}/releases/${releaseId}`);
  }

  uploadReleaseAttachment(file: FormData, soId: number, releaseId: number) {
    return this.post<FormData>(file, { force: true }, `${soId}/releases/${releaseId}/attachments`, {
      reportProgress: true,
      observe: 'events'
    });
  }

  downloadReleaseAttachment(attachmentId: string, soId: number, releaseId: number): Observable<Blob> {
    return this.get({}, `${soId}/releases/${releaseId}/attachments/${attachmentId}`, { responseType: 'blob' });
  }

  deleteReleaseAttachment(attachmentId: string, soId: number, releaseId: number) {
    return this.delete({}, `${soId}/releases/${releaseId}/attachments/${attachmentId}`);
  }

  getReleaseAttachments(soId: number, releaseId: number) {
    return this.get<IPurchaseRequisitionAttachments>({}, `${soId}/releases/${releaseId}/attachments`);
  }

  getSalesOrdersReadyForPickup<T>(params: object): Observable<ICollectionResponse<ILoadingAuthSalesOrder>> {
    return this.get<ICollectionResponse<ILoadingAuthSalesOrder>>(params, 'readyForPickup');
  }
  // end of original methods

  getById(id: string) {
    return this.get<ISalesOrderResponse>({}, id).pipe(map(order => this.salesOrderFactory.fromResponse(order)));
  }

  getProgressById(id: string): Observable<IERPSalesOrderProgresStatus> {
    return this.get<IERPSalesOrderProgresStatus>({}, `${id}/progress`);
  }

  getSalesOrdersReferences(params: object) {
    return this.get<ICollectionResponse<ISalesOrderReferences>>(params, 'reference');
  }

  getAvailableStatuses(currentStatusId?: number) {
    const params: { [key: string]: string | number } = {};
    if (currentStatusId) {
      params.currentStatusId = currentStatusId;
    }

    return this.get<ISelectOption[]>(params, 'availableStatuses');
  }

  getAvailableStatusesBySOId(currentStatusId?: number, soId?: number): Observable<ISalesOrderAvaluableTransitions[]> {
    const params: { [key: string]: string | number } = {};
    if (currentStatusId) {
      params.currentStatusId = currentStatusId;
    }

    return this.get<ISalesOrderAvaluableTransitions[]>(params, `${soId}/availableTransitions`);
  }

  uploadAttachment(
    file: FormData,
    soId: number,
    params: { categoryId: ERPSalesOrderAttachmentsCategory; force?: boolean }
  ) {
    return this.post<FormData>(file, params, `${soId}/attachments`, {
      reportProgress: true,
      observe: 'events'
    });
  }

  downloadAttachment(attachmentId: string, soId: number): Observable<Blob> {
    return this.get({}, `${soId}/attachments/${attachmentId}`, { responseType: 'blob' });
  }

  deleteAttachment(attachmentId: string, soId: number) {
    return this.delete({}, `${soId}/attachments/${attachmentId}`);
  }

  getAttachments(soId: number) {
    return this.get<IERPSalesOrderAttachments>({}, `${soId}/attachments`);
  }

  getRevisionHistory(soId: number): Observable<ISalesOrderRevision[]> {
    return this.get<ISalesOrderRevision[]>({}, `${soId}/logs`);
  }

  getAvailableLineStatusTransitions(currentStatusId: number | string) {
    return this.get<ISalesOrderAvaluableTransitions[]>({ currentStatusId }, 'availableLineStatusTransitions');
  }

  onNotifyAccounting(soId: number) {
    return this.post({}, {}, `${soId}/notification/insufficientCustomerLimit`);
  }

  getAssociatedPurchaseOrders(id: number | string, query: any) {
    return this.get({ query }, `${id}/associatedPurchaseOrders`);
  }

  getAssociatedWorkOrders(
    id: number | string,
    query: any
  ): Observable<ICollectionResponse<ISalesOrderAssociatedWorkOrders>> {
    return this.get<ICollectionResponse<ISalesOrderAssociatedWorkOrders>>(
      { query },
      `${id}/associatedProcessingOrders`
    );
  }
}
