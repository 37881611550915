import { inject, Injectable, OnDestroy } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep, set, isEqual } from 'lodash';
import {
  Subject,
  BehaviorSubject,
  Observable,
  map,
  ReplaySubject,
  filter,
  combineLatest,
  switchMap,
  tap,
  debounceTime,
  takeUntil
} from 'rxjs';
import { ERPConfirmComponent, IConfirmDialogData } from '../../confirm';
import { ERPSignalRConnectionService, ISignalRMessage, SignalRMessageType } from '@erp/shared';
import { IPageForm, PAGE_FORM } from './page-form';
import { DocumentOutDatedDialogComponent } from '../components/document-outdated-dialog/document-outdated-dialog.component';
import { ERPToasterService } from '../../toaster';

export interface IHTTPActionParams {
  [key: string]: any;
}
export type ParentForm = FormGroup | UntypedFormGroup | FormArray | UntypedFormArray;
export type PageForm = ParentForm | FormControl | UntypedFormControl;
export type DataDifference = { [path: string]: [baseValue: any, changedValue: any] };

@Injectable()
export abstract class ERPDocumentState<T = any> implements OnDestroy {
  private destroy$ = new Subject<void>();
  private _state$: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);
  private readonly $dialog: MatDialog = inject(MatDialog);
  private readonly signalR: ERPSignalRConnectionService = inject(ERPSignalRConnectionService);
  private readonly $toaster: ERPToasterService = inject(ERPToasterService);

  protected readonly $route: ActivatedRoute = inject(ActivatedRoute);
  protected readonly form: PageForm = (inject(PAGE_FORM) as IPageForm).form;

  state$: Observable<T> = this._state$.pipe(
    map(data => {
      if (data === null) {
        return this.form.getRawValue();
      } else {
        return data;
      }
    })
  ) as Observable<T>;
  loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  documentUpdated$: Observable<ISignalRMessage> = this.signalR.messages$.pipe(
    takeUntil(this.destroy$),
    filter(message => {
      return (
        (this._state$.value as any)?.rowVersion &&
        message.Metadata.MessageType === SignalRMessageType.EvtDocumentUpdated &&
        (message.MessageBody as any).DocumentId === +this.$route.snapshot.params.id
      );
    })
  );

  constructor() {
    combineLatest([this.documentUpdated$, this.loading$])
      .pipe(
        takeUntil(this.destroy$),
        filter(([message, loading]) => {
          return !loading && (this._state$.value as any)?.rowVersion < (message.MessageBody as any).RowVersion;
        }),
        map(([event]) => event),
        // actions after event was filtered out
        switchMap((_: any) => {
          return this.loader();
        })
      )
      .subscribe(data => {
        const diff = this.difference(this._state$.value, this.form.getRawValue());
        const incomeChanges = this.difference(this._state$.value, data);
        const hasOverlapChanges = this.checkChangesOverlap(incomeChanges, diff);
        if (!hasOverlapChanges) {
          const touched = this.form.touched;
          const restoredData: T = this.restoreForm(diff, data);
          this._state$.next(restoredData);
          this.restoreFormState(touched);
          this.$toaster.info({
            title: $localize`:@@common.document-updated.notification.title:Document updated`,
            message: $localize`:@@common.document-updated.notification.message:Current document was updated by someone else on the background so your version is also updated`
          });
        } else {
          this.onShowOutdatedPopap();
        }
      });
  }

  get formChanges() {
    return this.difference(this._state$.value, this.form.getRawValue());
  }

  restoreFormState(touched: boolean) {
    if (!touched) return;

    this.form.markAsTouched();
  }

  restoreForm = (diff: DataDifference, data: T): T => {
    const clone: any = cloneDeep(data);
    const entries = Object.entries(diff);

    if (entries.length) {
      entries.forEach(([path, [, changedValue]]) => {
        set(clone, path, changedValue);
      });
    }

    return clone;
  };

  standartEffect = () =>
    tap(
      (data: T) => {
        this._state$.next(data);
      },
      e => {
        this.loading$.next(false);
      },
      () => {
        this.loading$.next(false);
      }
    );

  load(): Observable<T> {
    this.loading$.next(true);

    return this.loader().pipe(this.standartEffect());
  }
  create(): Observable<T> {
    this.loading$.next(true);
    return this.creator().pipe(this.standartEffect());
  }
  update(): Observable<T> {
    this.loading$.next(true);
    return this.updater().pipe(this.standartEffect());
  }

  patch(...params: any[]): Observable<T> {
    return this.patcher(...params).pipe(
      switchMap(_ => this.loader()),
      this.standartEffect()
    );
  }

  protected abstract loader(): Observable<T>;
  protected abstract creator(): Observable<T>;
  protected abstract updater(): Observable<T>;
  protected abstract patcher(...params: any[]): Observable<any>;

  private equalDates(date1: Date, date2: Date): boolean {
    return (
      date1?.getFullYear() === date2?.getFullYear() &&
      date1?.getMonth() === date2?.getMonth() &&
      date1?.getDate() === date2?.getDate()
    );
  }

  difference = (obj1: any, obj2: any, diff: any = {}, path: string[] = []): any => {
    const baseScenarios = [
      typeof obj1 !== 'object' || obj1 === null || obj1 instanceof Date || obj2 instanceof Date,
      obj1 !== obj2,
      obj2 !== undefined
    ];
    const cases: boolean[] = [
      (obj1 instanceof Date || obj2 instanceof Date) && !this.equalDates(obj1, obj2), // case for dates
      !(obj1 === null && obj2 === 0)
    ];

    if (baseScenarios.every(scenario => scenario) && cases.some(scenario => scenario)) {
      diff[path.join('.')] = [obj1, obj2];
    } else if (Array.isArray(obj1) && obj1 !== null && obj1.length < obj2.length) {
      obj2.slice(obj1.length).forEach((element: any, index: number) => {
        const key = obj1.length + index;
        diff[[...path, key].join('.')] = [obj1[key], element];
      });
    } else if (typeof obj1 === 'object' && obj1 !== null) {
      Object.entries(obj1).forEach(([key, value]) => {
        this.difference(value, obj2?.[key], diff, [...path, key]);
      });
    }

    return diff;
  };

  checkChangesOverlap = (incomeChanges: DataDifference, formChanges: DataDifference) => {
    let hasOverlap = false;
    Object.entries(incomeChanges).forEach(([path, [, newValue]]) => {
      const incomeValue = newValue;
      const formValue = formChanges[path]?.[1];

      if (formValue !== undefined && !isEqual(incomeValue, formValue)) {
        hasOverlap = true;
      }
    });

    return hasOverlap;
  };

  onShowOutdatedPopap() {
    const ref = this.$dialog.open(DocumentOutDatedDialogComponent, {
      disableClose: true,
      data: {
        confirm: $localize`:@@sales.sales-order.document-outdated.confirm:Reload now`,
        cancelAction: true,
        allowClose: false,
        allowCancel: false,
        isShowCancel: false,
        header: $localize`:@@sales.sales-order.document-outdated.confirm-header:Reload tab to continue modifying.`
      } as IConfirmDialogData
    });

    ref
      .afterClosed()
      .pipe(switchMap(() => this.load()))
      .subscribe();
  }

  // lifecycle
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
